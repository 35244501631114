import React from "react"

const IbanCalculator = props => {
  return <div>IbanCalculator</div>
}

IbanCalculator.defaultProps = {
  title: "Iban ",
}

export default IbanCalculator
